<template>
  <div v-if="inputAudioData" class="daily-audio">
    <div v-for="(item, index) in inputAudioData" :key="index">
      <div class="title d-flex justify-content-around align-self-center">
        <div style="width:50%; text-align: left;">
          <h5 class="date">{{moment(item.publish_at).format('YYYY/MM/DD') }}</h5>
        </div>
        <div style="width:50%; text-align: right;">
          <span v-if="!item.collection" @click="addCollections(item)" class="pointer p-2">
            <img src="../assets/image/icon&btn/icon_have_btn_function/btn_function_mark_off.png" alt="">
            <span class="rwd-hide">{{ $t('Home.Keep') }}</span>
          </span>
          <span v-else  @click="removeCollections(item.id)" class="pointer p-2">
            <img class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
            <span class="rwd-hide">{{ $t('Home.Keep') }}</span>
          </span>
          <span v-if="inputAudioData.length===1" class="pointer p-2" @click="$router.push({ name: 'Sentence'})"><img  src="../assets/image/icon&btn/icon_have_btn_function/btn_function_show_list.png" alt="">
            <span span class="rwd-hide">{{ $t('Home.watch_more') }}</span>
          </span>
        </div>
      </div>
      <div class="daily-box" style="min-height:100px !important">
        <div>
          <h4 class="d-flex align-items-center flex-wrap">
            <span class="pointer pr-2" @click="playSentences(index)">
              <img style="width:30px" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_play_voice.png" alt="">
            </span>
            <!-- <span v-html=" forceToJap(splitJpSentence(item.jp))"></span> -->
            <div class="px-1 sentence" v-for="(el, idx) in splitJpSentence(item)" :key="idx">
              <span v-if="item.dict">
                <span v-html="forceToJap(el)" :class="{'dict': item.dict.find((i=>{ return i.key === el}))}" @click="setVocabulary(el,item.dict)"></span>
              </span>
              <div class="sentence-box" v-if="vocabularyData && vocabularyData.key === el">
                <div class="sentence">
                  <font-awesome-icon class="pointer close" @click="closeDict" size="lg" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
                  <h5 class="sentence-title">
                    <span class="pointer" @click="playVocabulary(el)">
                      <span class="dict" v-html="forceToJap(vocabularyData.key)"></span>
                      <img style="width:30px" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_play_voice.png" alt="">
                    </span>
                  </h5>
                  <span class="sentence-phon">
                    {{vocabularyData.part_of_speech}}
                  </span>
                  <span class="sentence-phon">
                    {{vocabularyData.phon}}
                  </span>
                  <span class="sentence-explain">
                    {{vocabularyData.expl}}
                  </span>
                </div>
                <audio v-if="vocabularyData" :id="`vocabulary${el}`" :ref="`vocabulary`">
                  <source :src="`https://apitest.liveabc.com/v1/livelibrary/Dict/Word/${vocabularyData.key}/audio?lang=ja`" type="audio/mp3">
                </audio>
              </div>
            </div>
          </h4>
          <h4 class="my-auto px-1">{{item.local}}</h4>
        </div>
      </div>
      <audio :id="`daily-sentences${index}`" :ref="`sentences`">
        <source :src="item.audio_url" type="audio/mp3">
      </audio>
      <slot name="line">
      </slot>
    </div>
  </div>
</template>

<script>
import api from '../store/api';
import VocabulariesVue from '../views/Toeic/Vocabularies.vue';
export default {
  name: 'DailySentence',
  data () {
    return {
      vocabularyData: null
    }
  },
  components: {
  },
  props: {
    inputAudioData: {
      required: true
    }
  },
  computed: {
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.audio ? this.$refs.audio.load() : null
    })
  },
  methods: {
    playSentences (id) {
      let audio = document.getElementById(`daily-sentences${id}`)
      this.pauseAll()
      audio.load()
      audio.play()
    },
    playVocabulary (el) {
      let audio = document.getElementById(`vocabulary${el}`)
      this.pauseAll()
      audio.load()
      audio.play()
    },
    pauseAll () {
      const audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        // 将audios中其他的audio全部暂停
        i.currentTime = 0
        i !== self && i.pause()
      })
    },
    close (close) {
      this.toggle = close
    },
    async addCollections (el) {
      try {
        const fileData = new window.FormData()
        fileData.append('name', el.english)
        fileData.append('json', JSON.stringify(el))
        await api.post(`collections/daily_sentence/${el.id}`, fileData)
        this.$parent.getDailySentences()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    },
    async removeCollections (id) {
      try {
        await api.delete(`collections/daily_sentence/${id}`)
        this.$parent.getDailySentences()
      } catch (error) {
        console.error(error.response.data)
      }
    },
    setVocabulary (val,dict) {
      for (let i in dict) {
        if (dict[i].key === val) {
          this.vocabularyData = dict[i]
        }
      }
    },
    closeDict () {
      this.vocabularyData = null
    },
    splitJpSentence (el) {
      let sent = el.sent
      for (let i =0; i < el.dict.length; i++) {
        sent = sent.replace(el.dict[i].key, `~${el.dict[i].key}~`)
      }
      console.log(sent.split(/[~*~]/)[sent.split(/[~*~]/).length-1])
      const newSent = sent.split(/[~*~]/)
      return newSent
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../assets/styles/custom.scss";
  .daily-box {
    padding: 20px;
    h4 {
      line-height: 3rem;
      font-weight: 600;
    }
    text-align: left;
  }
  .date {
    font-size: 1rem;
  }
  .dict {
    color: rgb(255, 0, 0);
    cursor: pointer;
    text-decoration: underline;
  }
  .sentence {
    position: relative;
    .sentence-box {
      overflow: auto;
      box-shadow: 3px 3px 5px #afafaf;
      font-size: .8rem;
      padding: 5px 10px;
      width: 250px;
      position: absolute;
      max-height: 200px;
      border: 1px solid red;
      background-color: #fff;
      z-index: 40;
      .sentence {
        .sentence-title {
          color: red;
          line-height: 2.5rem;
          font-weight: 600;
          margin: 0;
        }
        .sentence-phon {
          font-size: .5rem;
          line-height: 1rem;
          margin: 0;
        }
        .sentence-explain {
          font-size: 1rem;
          font-weight: 600;
          line-height: 2rem;
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
	.sentence-box {
    position: relative !important;
    width:80vw !important;
  }
}
</style>
